import { useCallback, useEffect, useRef } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import { ScopeType } from "sockapi/scope-types";
import { SockClient } from "sockapi/client/SockClient";
import { useNavStore } from "stores/NavStore";
import settings from "settings";

/**
 * clsObj({
 *   "puzzle-card": true,
 *   "solved": false,
 *   "active": true,
 * }) // => "puzzle-card active"
 */
export function clsObj(clsObj: Record<string, boolean>): string {
  return Object.entries(clsObj)
    .filter(([, value]) => value)
    .map(([key]) => key)
    .join(" ");
}

export function displayNameOrder(name1: string, name2: string): number {
  const parts1 = name1.replace(/^\[\w+\] /, "").split(/(\d+)/);
  const parts2 = name2.replace(/^\[\w+\] /, "").split(/(\d+)/);
  for (let i = 0; i < parts1.length || i < parts2.length; ++i) {
    if (i >= parts1.length) return -1;
    if (i >= parts2.length) return 1;
    const part1 = parts1[i];
    const part2 = parts2[i];
    if (part1 === part2) continue;
    if (i % 2) return +part1 - +part2;
    return part1.localeCompare(part2);
  }
  return 0;
}

/**
 * Utility function that allows us to define event callbacks that
 * use the most recent values of its dependencies without triggering
 * a re-render when its dependencies change.
 * This is safe as long as we don't use such functions for actually
 * rendering the component.
 */
export function useEvent<TArgs extends unknown[], TRet>(
  fn: (...args: TArgs) => TRet
): (...args: TArgs) => TRet {
  const ref = useRef<(...args: TArgs) => TRet>(fn);

  useEffect(() => {
    ref.current = fn;
  }, [fn]);

  return useCallback<(...args: TArgs) => TRet>(
    (...args: TArgs) => ref.current(...args),
    []
  );
}

// TODO: This is necessary because stale cached scope may
// cause the client to think something doesn't exist when
// in fact it was newly created. It would be nicer to just
// track when scope state is stale and have a way to wait
// for acked states.
export const addFlushSockAPIMessagesEffect = (
  sockClient: SockClient,
  onDone: () => void
) => {
  return sockClient.addAuthEffect(() => {
    let isCanceled = false;
    (async () => {
      // HACK: Requests are serialized, so after getting a
      // response for the first step, we should have expected
      // to get responses for all earlier subscription.
      // Send another one in case there are subscriptions after
      // this, and a third one just to be safe in case there's
      // some race condition with React rerenders. If we get the
      // team state during this period, a rerender will cancel
      // the effect.
      for (let i = 0; i < 3; i++) {
        const resp = await sockClient.sendDummyScopeStepAndWaitAsync();
        // The step may fail if we get disconnected. If this happens,
        // rely on the auth effect to retrigger when we reconnect.
        if (resp === null || isCanceled) return;
      }

      onDone();
    })().catch(console.error);
    return () => {
      isCanceled = true;
    };
  });
};

export const useRedirectListener = () => {
  const redirectCommand = useNavStore((state) => state.redirectCommand);
  const setRedirectCommand = useNavStore((state) => state.setRedirectCommand);
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const { clientVersion } = settings;

  useEffect(() => {
    if (redirectCommand === null) return;
    navigate(
      {
        pathname: redirectCommand.path,
        search: searchParams.toString(),
      },
      {
        replace: redirectCommand.replace,
      }
    );
    setRedirectCommand(null);

    void (async () => {
      if (!redirectCommand.isManual) return;
      if (clientVersion === undefined) return;
      const newVersion = await (await fetch("/client_version")).text();
      if (clientVersion === newVersion) return;
      window.location.reload();
    })();
  }, [
    redirectCommand,
    setRedirectCommand,
    searchParams,
    navigate,
    clientVersion,
  ]);
};

/**
 * Make the URL absolute to work in dev. Fallback to the full
 * URL so that Djangoless works.
 */
export const makeSubmittedImageUrl = (url: string): string =>
  url.startsWith("/") ? `${settings.djangoBaseUrl}${url.slice(1)}` : url;

export const downloadTeamStateAsync = async (
  sockClient: SockClient,
  teamId: string,
  getIsCanceled: () => boolean
): Promise<void> => {
  const payload = await sockClient.sendScopeStepAndWaitAsync(
    {
      type: ScopeType.STATE_DOWNLOAD,
    },
    {
      downloadTeams: [teamId],
      downloadPublicCustomPuzzles: true,
    }
  );
  if (getIsCanceled() || payload === null) return;
  const blob = new Blob([JSON.stringify(payload)], {
    type: "application/json",
  });
  const url = URL.createObjectURL(blob);
  const elem = document.createElement("a");
  elem.href = url;
  elem.download = "team-state.json";
  elem.style.display = "none";
  document.body.appendChild(elem);
  elem.click();
  document.body.removeChild(elem);
};
