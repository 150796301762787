import { ScopeType } from "sockapi/scope-types";
import { ScopeIdentifierType, Scope } from "sockapi/scopes";
import {
  SockAPIScopeType,
  SockAPIScopeIdentifierType,
  SockAPIScopeState,
} from "sockapi/scope-state";
import {
  SockAPIUpdatableScopeType,
  SockAPIScopeUpdatePayload,
} from "sockapi/scope-updates";

import {
  DummyScopeSpec,
  dummyScopeHooks,
} from "sockapi/scope-specs/dummy-spec";
import {
  HuntAdminScopeSpec,
  huntAdminScopeHooks,
} from "sockapi/scope-specs/hunt-admin-spec";
import {
  ServerHealthScopeSpec,
  serverHealthScopeHooks,
} from "sockapi/scope-specs/server-health-spec";
import {
  BigBoardScopeSpec,
  bigBoardScopeHooks,
} from "sockapi/scope-specs/big-board-spec";
import {
  TeamListScopeSpec,
  teamListScopeHooks,
} from "sockapi/scope-specs/team-list-spec";
import {
  TeamMembersScopeSpec,
  teamMembersScopeHooks,
} from "sockapi/scope-specs/team-members-spec";
import { TeamScopeSpec, teamScopeHooks } from "sockapi/scope-specs/team-spec";
import {
  TeamAnswerableScopeSpec,
  teamAnswerableScopeHooks,
} from "sockapi/scope-specs/team-answerable-spec";
import {
  PuzzleContentScopeSpec,
  puzzleContentScopeHooks,
} from "sockapi/scope-specs/puzzle-content-spec";
import {
  TeamPuzzle105ScopeSpec,
  teamPuzzle105ScopeHooks,
} from "sockapi/scope-specs/team-puzzle-105-spec";
import {
  ErratumContentScopeSpec,
  erratumContentScopeHooks,
} from "sockapi/scope-specs/erratum-content-spec";
import {
  TeamErrataScopeSpec,
  teamErrataScopeHooks,
} from "sockapi/scope-specs/team-errata-spec";
import {
  SolutionContentScopeSpec,
  solutionContentScopeHooks,
} from "sockapi/scope-specs/solution-content-spec";
import {
  CannedHintScopeSpec,
  cannedHintScopeHooks,
} from "sockapi/scope-specs/canned-hint-spec";
import {
  TeamCannedHintsScopeSpec,
  teamCannedHintsScopeHooks,
} from "sockapi/scope-specs/team-canned-hints-spec";
import {
  TeamHintsScopeSpec,
  teamHintsScopeHooks,
} from "sockapi/scope-specs/team-hints-spec";
import {
  KeyframeScopeSpec,
  keyframeScopeHooks,
} from "sockapi/scope-specs/keyframe-spec";
import {
  TeamCutscenesScopeSpec,
  teamCutscenesScopeHooks,
} from "sockapi/scope-specs/team-cutscenes-spec";
import { HintScopeSpec, hintScopeHooks } from "sockapi/scope-specs/hint-spec";
import {
  TeamGachaScopeSpec,
  teamGachaScopeHooks,
} from "sockapi/scope-specs/team-gacha-spec";
import {
  MockDirectBackendScopeSpec,
  mockDirectBackendScopeHooks,
} from "sockapi/scope-specs/mock-direct-backend-spec";
import {
  TeamOliveScopeSpec,
  teamOliveScopeHooks,
} from "sockapi/scope-specs/team-olive-spec";
import {
  AdminQueueScopeSpec,
  adminQueueScopeHooks,
} from "sockapi/scope-specs/admin-queue-spec";
import {
  AdminQueueTaskScopeSpec,
  adminQueueTaskScopeHooks,
} from "sockapi/scope-specs/admin-queue-task-spec";
import {
  TeamBlocksScopeSpec,
  teamBlocksScopeHooks,
} from "sockapi/scope-specs/team-blocks-spec";
import {
  TeamCollectiblesScopeSpec,
  teamCollectiblesScopeHooks,
} from "sockapi/scope-specs/team-collectibles-spec";
import {
  TeamFarmerScopeSpec,
  teamFarmerScopeHooks,
} from "sockapi/scope-specs/team-farmer-spec";
import {
  TeamBigBranchScopeSpec,
  teamBigBranchScopeHooks,
} from "sockapi/scope-specs/team-big-branch-spec";
import {
  TeamPuzzle144ScopeSpec,
  teamPuzzle144ScopeHooks,
} from "sockapi/scope-specs/team-puzzle-144-spec";
import {
  HopePoolScopeSpec,
  hopePoolScopeHooks,
} from "sockapi/scope-specs/hope-pool-spec";
import {
  TeamHopePoolScopeSpec,
  teamHopePoolScopeHooks,
} from "sockapi/scope-specs/team-hope-pool-spec";
import {
  TeamHopelessScopeSpec,
  teamHopelessScopeHooks,
} from "sockapi/scope-specs/team-hopeless-spec";
import {
  TeamHistoryScopeSpec,
  teamHistoryScopeHooks,
} from "sockapi/scope-specs/team-history-spec";
import {
  TeamTitlesScopeSpec,
  teamTitlesScopeHooks,
} from "sockapi/scope-specs/team-titles-spec";
import {
  TeamPuzzle139ScopeSpec,
  teamPuzzle139ScopeHooks,
} from "sockapi/scope-specs/team-puzzle-139-spec";
import {
  StateDownloadScopeSpec,
  stateDownloadScopeHooks,
} from "sockapi/scope-specs/state-download-spec";

export type ScopeSpecs = {
  [ScopeType.DUMMY]: DummyScopeSpec;
  [ScopeType.HUNT_ADMIN]: HuntAdminScopeSpec;
  [ScopeType.SERVER_HEALTH]: ServerHealthScopeSpec;
  [ScopeType.BIG_BOARD]: BigBoardScopeSpec;
  [ScopeType.TEAM_LIST]: TeamListScopeSpec;
  [ScopeType.TEAM_MEMBERS]: TeamMembersScopeSpec;
  [ScopeType.TEAM]: TeamScopeSpec;
  [ScopeType.TEAM_ANSWERABLE]: TeamAnswerableScopeSpec;
  [ScopeType.PUZZLE_CONTENT]: PuzzleContentScopeSpec;
  [ScopeType.TEAM_PUZZLE_105]: TeamPuzzle105ScopeSpec;
  [ScopeType.ERRATUM_CONTENT]: ErratumContentScopeSpec;
  [ScopeType.TEAM_ERRATA]: TeamErrataScopeSpec;
  [ScopeType.SOLUTION_CONTENT]: SolutionContentScopeSpec;
  [ScopeType.CANNED_HINT]: CannedHintScopeSpec;
  [ScopeType.HINT]: HintScopeSpec;
  [ScopeType.TEAM_GACHA]: TeamGachaScopeSpec;
  [ScopeType.TEAM_CANNED_HINTS]: TeamCannedHintsScopeSpec;
  [ScopeType.TEAM_HINTS]: TeamHintsScopeSpec;
  [ScopeType.KEYFRAME]: KeyframeScopeSpec;
  [ScopeType.TEAM_CUTSCENES]: TeamCutscenesScopeSpec;
  [ScopeType.MOCK_DIRECT_BACKEND]: MockDirectBackendScopeSpec;
  [ScopeType.TEAM_OLIVE]: TeamOliveScopeSpec;
  [ScopeType.ADMIN_QUEUE]: AdminQueueScopeSpec;
  [ScopeType.ADMIN_QUEUE_TASK]: AdminQueueTaskScopeSpec;
  [ScopeType.TEAM_BLOCKS]: TeamBlocksScopeSpec;
  [ScopeType.TEAM_COLLECTIBLES]: TeamCollectiblesScopeSpec;
  [ScopeType.TEAM_FARMER]: TeamFarmerScopeSpec;
  [ScopeType.TEAM_BIG_BRANCH]: TeamBigBranchScopeSpec;
  [ScopeType.TEAM_PUZZLE_144]: TeamPuzzle144ScopeSpec;
  [ScopeType.HOPE_POOL]: HopePoolScopeSpec;
  [ScopeType.TEAM_HOPE_POOL]: TeamHopePoolScopeSpec;
  [ScopeType.TEAM_HOPELESS]: TeamHopelessScopeSpec;
  [ScopeType.TEAM_HISTORY]: TeamHistoryScopeSpec;
  [ScopeType.TEAM_TITLES]: TeamTitlesScopeSpec;
  [ScopeType.TEAM_PUZZLE_139]: TeamPuzzle139ScopeSpec;
  [ScopeType.STATE_DOWNLOAD]: StateDownloadScopeSpec;
};

export type ScopeIdComponents = (string | number)[];

type ScopeHooksBase<TScopeType extends ScopeType> = {
  identityFunc: (scope: Scope & { type: TScopeType }) => Scope<TScopeType>;
};
type ScopeIdentifierHooks<TScopeType extends ScopeIdentifierType> = {
  getIdComponents: (scope: Scope<TScopeType>) => ScopeIdComponents;
};
type UpdatableScopeHooks<TScopeType extends SockAPIUpdatableScopeType> = {
  update: (
    scopeState: SockAPIScopeState<TScopeType>,
    payload: SockAPIScopeUpdatePayload<TScopeType>
  ) => void;
};

export type ScopeHooks<TScopeType extends ScopeType> =
  ScopeHooksBase<TScopeType> &
    (TScopeType extends ScopeIdentifierType
      ? ScopeIdentifierHooks<TScopeType>
      : unknown) &
    (TScopeType extends SockAPIUpdatableScopeType
      ? UpdatableScopeHooks<TScopeType>
      : unknown) & {
      disableClientCache?: true;
    };

export const scopeHooks: {
  [TScopeType in ScopeType]: ScopeHooks<TScopeType>;
} = {
  [ScopeType.DUMMY]: dummyScopeHooks,
  [ScopeType.HUNT_ADMIN]: huntAdminScopeHooks,
  [ScopeType.SERVER_HEALTH]: serverHealthScopeHooks,
  [ScopeType.BIG_BOARD]: bigBoardScopeHooks,
  [ScopeType.TEAM_LIST]: teamListScopeHooks,
  [ScopeType.TEAM_MEMBERS]: teamMembersScopeHooks,
  [ScopeType.TEAM]: teamScopeHooks,
  [ScopeType.TEAM_ANSWERABLE]: teamAnswerableScopeHooks,
  [ScopeType.PUZZLE_CONTENT]: puzzleContentScopeHooks,
  [ScopeType.TEAM_PUZZLE_105]: teamPuzzle105ScopeHooks,
  [ScopeType.ERRATUM_CONTENT]: erratumContentScopeHooks,
  [ScopeType.TEAM_ERRATA]: teamErrataScopeHooks,
  [ScopeType.SOLUTION_CONTENT]: solutionContentScopeHooks,
  [ScopeType.CANNED_HINT]: cannedHintScopeHooks,
  [ScopeType.HINT]: hintScopeHooks,
  [ScopeType.TEAM_GACHA]: teamGachaScopeHooks,
  [ScopeType.TEAM_CANNED_HINTS]: teamCannedHintsScopeHooks,
  [ScopeType.TEAM_HINTS]: teamHintsScopeHooks,
  [ScopeType.KEYFRAME]: keyframeScopeHooks,
  [ScopeType.TEAM_CUTSCENES]: teamCutscenesScopeHooks,
  [ScopeType.MOCK_DIRECT_BACKEND]: mockDirectBackendScopeHooks,
  [ScopeType.TEAM_OLIVE]: teamOliveScopeHooks,
  [ScopeType.ADMIN_QUEUE]: adminQueueScopeHooks,
  [ScopeType.ADMIN_QUEUE_TASK]: adminQueueTaskScopeHooks,
  [ScopeType.TEAM_BLOCKS]: teamBlocksScopeHooks,
  [ScopeType.TEAM_COLLECTIBLES]: teamCollectiblesScopeHooks,
  [ScopeType.TEAM_FARMER]: teamFarmerScopeHooks,
  [ScopeType.TEAM_BIG_BRANCH]: teamBigBranchScopeHooks,
  [ScopeType.TEAM_PUZZLE_144]: teamPuzzle144ScopeHooks,
  [ScopeType.HOPE_POOL]: hopePoolScopeHooks,
  [ScopeType.TEAM_HOPE_POOL]: teamHopePoolScopeHooks,
  [ScopeType.TEAM_HOPELESS]: teamHopelessScopeHooks,
  [ScopeType.TEAM_HISTORY]: teamHistoryScopeHooks,
  [ScopeType.TEAM_TITLES]: teamTitlesScopeHooks,
  [ScopeType.TEAM_PUZZLE_139]: teamPuzzle139ScopeHooks,
  [ScopeType.STATE_DOWNLOAD]: stateDownloadScopeHooks,
};

// Type specializations to convince typescript that the respective
// hooks satisfy their specific type requirements.
// Note that we cannot rely on the key sets of these dictionaries
// for type checking since they are typed more narrowly than the
// key set of scopeHooks.
export const updatableScopeHooks: {
  [TScopeType in SockAPIUpdatableScopeType]: UpdatableScopeHooks<TScopeType>;
} = scopeHooks;
export const globalScopeHooks: {
  [TScopeType in Exclude<SockAPIScopeType, SockAPIScopeIdentifierType>]: {
    identityFunc: (scope: { type: TScopeType }) => Scope<TScopeType>;
  };
} = scopeHooks;
