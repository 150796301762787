import Globals from "Globals";

const urlParams = new URLSearchParams(window.location.search);

const paramToBool = (param: string | null) => {
  if (param == null) {
    return false;
  }
  // ?param or ?param=1
  return param == "" || param == "1";
};

const windowTeamId = Globals.teamId ?? null;
const djangoBaseUrl = Globals.djangoBaseUrl ?? "http://localhost:8000/";
const isDjangoServed = Globals.djangoBaseUrl !== undefined;
/**
 * Set only for non-logged-in teams after the hunt is over.
 * Check the current time against huntEndTime instead if a behavior
 * should change for a logged-in team after hunt ends.
 */
const isPosthunt =
  paramToBool(urlParams.get("posthunt")) || (Globals.isPosthunt ?? false);
const forceHuntEnd = isPosthunt || paramToBool(urlParams.get("huntend"));
const hasAdminAccessOpt = urlParams.get("admin");
const hasAdminAccess =
  hasAdminAccessOpt !== null
    ? paramToBool(hasAdminAccessOpt)
    : Globals.hasAdminAccess ??
      (import.meta.env.MODE === "development" && !isPosthunt);
const isImpersonate = Globals.isImpersonate ?? false;
const bootstrapTeam = isPosthunt
  ? {
      teamId: "postsolver",
      displayName: "Postsolver",
    }
  : undefined;

const DOCUMENT_TITLE = "Stargazing";
const devModeOpt = urlParams.get("dev");
const devMode =
  devModeOpt === null
    ? import.meta.env.MODE === "development"
    : paramToBool(devModeOpt);
const fixedTeamId = urlParams.get("team") ?? windowTeamId;
const wsEndpointOpt = urlParams.get("ws");
const wsEndpoint =
  wsEndpointOpt !== null &&
  wsEndpointOpt !== "" &&
  // Don't allow setting WS endpoints through urlparams outside
  // dev to prevent XSS attacks.
  import.meta.env.MODE === "development"
    ? wsEndpointOpt
    : Globals.wsEndpoint ??
      (isDjangoServed
        ? import.meta.env.VITE_DJANGO_WS_ENDPOINT
        : import.meta.env.VITE_WS_ENDPOINT) ??
      "ws://localhost:8080";
const cursorsWsEndpointOpt = urlParams.get("cursorsws");
const cursorsWsEndpoint =
  cursorsWsEndpointOpt !== null &&
  cursorsWsEndpointOpt !== "" &&
  // Don't allow setting WS endpoints through urlparams outside
  // dev to prevent XSS attacks.
  import.meta.env.MODE === "development"
    ? cursorsWsEndpointOpt
    : Globals.cursorsWsEndpoint !== undefined
    ? Globals.cursorsWsEndpoint
    : wsEndpoint;
const farmerWsEndpointsStrFromEnv = import.meta.env.VITE_FARMER_WS_ENDPOINTS;
const farmerWsEndpoints =
  farmerWsEndpointsStrFromEnv !== undefined
    ? JSON.parse(farmerWsEndpointsStrFromEnv)
    : ["wss://interactive.galacticpuzzlehunt.com/farmer-ws"];
const localModeOpt = paramToBool(urlParams.get("local"));
const useDjangoOpt = urlParams.get("django");
const useDjango =
  useDjangoOpt !== null
    ? paramToBool(useDjangoOpt)
    : !localModeOpt && isDjangoServed && !isPosthunt;
const localMode =
  localModeOpt ||
  isPosthunt ||
  (devMode &&
    wsEndpointOpt === null &&
    !useDjango &&
    !paramToBool(urlParams.get("online")));
const useJwtOpt = urlParams.get("jwt");
const useJwt = useJwtOpt !== null ? paramToBool(useJwtOpt) : useDjango;
const useDirectBackendOpt = urlParams.get("directbackend");
const useDirectBackend =
  useDirectBackendOpt !== null ? paramToBool(useDirectBackendOpt) : useDjango;
const sharedWorkerOpt = paramToBool(urlParams.get("shared"));
const useSharedWorker = sharedWorkerOpt || isPosthunt;

// Disable the scope state cache in posthunt since it might cause
// confusing errors for users.
// Keep it enabled for local dev to catch caching bugs.
const enableScopeStateCacheOpt = urlParams.get("cache");
const enableScopeStateCache =
  enableScopeStateCacheOpt !== null
    ? paramToBool(enableScopeStateCacheOpt)
    : !isPosthunt;

const clientDelayOpt = urlParams.get("clientdelay");
const clientDelay =
  clientDelayOpt === null
    ? 0
    : clientDelayOpt === ""
    ? 1000
    : parseInt(clientDelayOpt);

if (useDjango && (fixedTeamId ?? null) === null)
  throw new Error(
    "no teamId found for django (in dev, this needs to be specified in the url as ?team=<teamId>)"
  );

const settings = {
  documentTitle: DOCUMENT_TITLE,
  browserStoragePrefix: "gph24",
  devMode,
  localMode,
  wsEndpoint,
  cursorsWsEndpoint,
  djangoBaseUrl,
  useJwt,
  useSharedWorker,
  isPosthunt,
  hasWrapup: false,
  hasAdminAccess,
  isImpersonate,
  fixedTeamId,
  dumpWsMessages: paramToBool(urlParams.get("dumpws")),
  dumpBackendRequests: paramToBool(urlParams.get("dumpbackend")),
  clientDelay,
  enableScopeStateCache,
  ignoreHopeLimit: paramToBool(urlParams.get("infhope")),
  gitCommitHash: import.meta.env.VITE_GIT_COMMIT_HASH,
  directBackendUrl: useDirectBackend
    ? new URL("direct_backend/", djangoBaseUrl).href
    : undefined,
  workshopMode: paramToBool(urlParams.get("workshop")),
  factcheckMode: paramToBool(urlParams.get("factcheck")),
  staticImagesDir: "/static/images",
  clientVersion: isDjangoServed ? new URL(import.meta.url).pathname : undefined,
};

export default settings;

export const mockServerOpts = {
  dumpBackendRequests: settings.dumpBackendRequests,
  browserStoragePrefix: settings.browserStoragePrefix,
  bootstrapTeam,
  isPosthunt: settings.isPosthunt,
  forceHuntEnd,
  farmerWsEndpoints,
};
