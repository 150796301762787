import { DistributiveOmit } from "util/util";
import { normalizeAnswer } from "sockapi/answers";
import { ScopeHooks } from "sockapi/scope-specs";
import { getTeamScopeIdComponents } from "sockapi/scope-specs-util";
import { ScopeType } from "sockapi/scope-types";
import { ScopeTeamIdentifier } from "sockapi/scopes";
import { SockAPIScopeState } from "sockapi/scope-state";
import { UnlockingInEdge, UnlockingOutEdge } from "sockapi/puzzle-data";
import { Rarity } from "sockapi/gacha/pulls";

export const PUZZLE_105_CONFIG = {
  puzName: "puz105",
  /**
   * WARNING: This prefix must be kept in sync with Django,
   * in gph/settings/base.py
   */
  customPuzzlePuzNamePrefix: "puz105_custom_",
  /**
   * WARNING: This prefix must be kept in sync with Django,
   * in gph/settings/base.py
   */
  customPuzzleSlugPrefix: "custom_puzzle_",
  cutsceneChoice: {
    cutscenePuzName: "submitting_your_application",
    keyframeId: "moonick-decide",
    freePuzzleIndex: 0,
    freeMoonickIndex: 1,
  },
  titleMaxLength: 10,
  checkTitleRateLimitIntervalMinutes: 30,
  checkTitleRateLimitNum: 5,
  formLimits: {
    shortInputMaxLength: 60,
    longInputMaxLength: 1000,
    answerMinLength: 1,
  },
};

export enum Puzzle105SharePermission {
  FULL = "full",
  WRAPUP = "wrapup",
  NONE = "none",
}

type Puzzle105FormFields = {
  title: string;
  answer: string;
  hexColor: string;
  difficulty: number | null;
  flavortext: string;
  solution: string;
  instructions: string;
  notes: string;
  sharePermission: Puzzle105SharePermission | null;
};

export type Puzzle105FormFieldsUpdate = {
  [k in keyof Puzzle105FormFields]?: NonNullable<Puzzle105FormFields[k]>;
};

type Puzzle105UpdateBase = {
  movePuz?: { puzName: string; diff: number };
  setTitle?: { puzName: string; val: string };
  formFields?: Puzzle105FormFieldsUpdate;
};

export enum Puzzle105CheckTitleResponse {
  CORRECT = "correct",
  WRONG = "wrong",
  RATE_LIMITED = "rate_limited",
  ALREADY_SOLVED = "already_solved",
}

export enum Puzzle105StatusType {
  WRONG_ORDER = "wrong_order",
  PENDING = "pending",
  ACCEPTED = "accepted",
  REJECTED = "rejected",
}

export type Puzzle105Status = (
  | {
      type: Puzzle105StatusType.WRONG_ORDER;
      /** Time when submission reopens. */
      reopenTime: number;
    }
  | {
      type: Puzzle105StatusType.PENDING;
    }
  | {
      type: Puzzle105StatusType.ACCEPTED;
      moonickDialogue: string;
      /** If set, other teams may get this puzzle in the gacha. */
      isAvailableInGacha?: true;
      /** Time when the review was completed. */
      reviewTime: number;
      rarity?: Rarity;
      cannedHint?: string;
      unlockInEdges?: UnlockingInEdge[];
      unlockOutEdges?: UnlockingOutEdge[];
    }
  | {
      type: Puzzle105StatusType.REJECTED;
      reason: string;
      /** Time when the review was completed. */
      reviewTime: number;
    }
) & {
  submitTime: number;
};

type Puzzle105SetStatusPayload = DistributiveOmit<
  Puzzle105Status,
  "reviewTime"
>;

/**
 * Returns the time when the submission was responeded to in admin review,
 * if it has.
 */
export const getTeamPuzzle105ReviewTime = ({
  status: submitStatus,
}: SockAPIScopeState<ScopeType.TEAM_PUZZLE_105>): number | null => {
  if (submitStatus === undefined) return null;
  switch (submitStatus.type) {
    case Puzzle105StatusType.ACCEPTED:
    case Puzzle105StatusType.REJECTED:
      return submitStatus.reviewTime;
    default:
      return null;
  }
};

/**
 * Returns the time when submission reopens for the team, or null
 * if submission is not locked.
 */
export const getTeamPuzzle105ReopenTime = ({
  status: submitStatus,
}: SockAPIScopeState<ScopeType.TEAM_PUZZLE_105>): number | null => {
  if (submitStatus === undefined) return null;
  if (submitStatus.type !== Puzzle105StatusType.WRONG_ORDER) return null;
  return submitStatus.reopenTime;
};

export const isTeamPuzzle105Editable = ({
  status: submitStatus,
}: SockAPIScopeState<ScopeType.TEAM_PUZZLE_105>) => {
  return !(
    submitStatus !== undefined &&
    [Puzzle105StatusType.PENDING, Puzzle105StatusType.ACCEPTED].includes(
      submitStatus.type
    )
  );
};

export const normalizeHexColor = (hexColor: string) => {
  return (
    hexColor.length === 3
      ? [...hexColor].map((c) => `${c}${c}`).join("")
      : hexColor
  )
    .toUpperCase()
    .replace(/[^0-9A-F]/g, "")
    .slice(0, 6)
    .padEnd(6, "0");
};

export const validateTeamPuzzle105MaxLengths = (
  formFields: Puzzle105FormFields | Puzzle105FormFieldsUpdate
): string[] => {
  const errs: string[] = [];
  const {
    title = "",
    answer = "",
    flavortext = "",
    solution = "",
    instructions = "",
    notes = "",
  } = formFields;
  const {
    formLimits: { shortInputMaxLength, longInputMaxLength },
  } = PUZZLE_105_CONFIG;

  if (title.length > shortInputMaxLength)
    errs.push(
      `The title to your Free Puzzle must be at most ${shortInputMaxLength} characters long.`
    );
  if (answer.length > shortInputMaxLength)
    errs.push(
      `The answer to your Free Puzzle must be at most ${shortInputMaxLength} characters long.`
    );
  if (flavortext.length > longInputMaxLength)
    errs.push(
      `The flavortext for your Free Puzzle must be at most ${longInputMaxLength} characters long.`
    );
  if (solution.length > longInputMaxLength)
    errs.push(
      `The solution for your Free Puzzle must be at most ${longInputMaxLength} characters long.`
    );
  if (instructions.length > longInputMaxLength)
    errs.push(
      `The additional instructions for your Free Puzzle must be at most ${longInputMaxLength} characters long.`
    );
  if (notes.length > longInputMaxLength)
    errs.push(
      `The notes for your Free Puzzle must be at most ${longInputMaxLength} characters long.`
    );

  return errs;
};

/**
 * Returns an array of error messages for any failed client-visible
 * validity checks.
 */
export const validateTeamPuzzle105 = (
  scopeState: SockAPIScopeState<ScopeType.TEAM_PUZZLE_105>
): string[] => {
  const errs: string[] = [];
  const { formFields, diagramUrl, confirmedTitles, order } = scopeState;
  const { answer, hexColor, difficulty, solution, sharePermission } =
    formFields;
  const {
    formLimits: { answerMinLength },
  } = PUZZLE_105_CONFIG;

  errs.push(...validateTeamPuzzle105MaxLengths(formFields));

  if (
    !answer
      .split("/")
      .every((subanswer) => subanswer.trim() === normalizeAnswer(subanswer))
  )
    errs.push("Invalid Free Puzzle answer. Only capital letters are allowed.");
  if (answer.length < answerMinLength)
    errs.push(
      `The answer to your Free Puzzle must be at least ${answerMinLength} character${
        answerMinLength === 1 ? "" : "s"
      } long.`
    );
  if (hexColor !== normalizeHexColor(hexColor)) errs.push("Invalid hex color.");
  if (difficulty === null) errs.push("A difficulty estimate is required.");
  else if (!Number.isInteger(difficulty) || difficulty < 0 || difficulty > 5)
    errs.push("Invalid difficulty estimate.");
  if (solution.length <= 0) errs.push("A solution is required.");
  if (sharePermission === null)
    errs.push(
      "You must indicate if you would like to share your Free Puzzle with other teams."
    );

  if (Object.keys(confirmedTitles).length < order.length)
    errs.push("Titles are required for all Supporting Documents.");
  if (diagramUrl === undefined) errs.push("A Diagram is required.");

  return errs;
};

export type TeamPuzzle105ScopeSpec = {
  identifier: ScopeTeamIdentifier;
  state: {
    formFields: Puzzle105FormFields;
    diagramUrl?: string;
    confirmedTitles: { [puzName: string]: string };
    checkTitleThrottledTime?: number;
    order: string[];
    /**
     * Enumerations for the titles, if we want to provide them.
     * Currently disabled.
     */
    enums: { [puzName: string]: number };
    status?: Puzzle105Status;
  };
  update: Puzzle105UpdateBase & {
    diagramUrl?: string;
    setConfirmedTitles?: {
      [puzName: string]: string | null;
    };
    setCheckTitleThrottledTime?: number | null;
    setStatus?: Puzzle105Status;
  };
  step: Puzzle105UpdateBase & {
    checkTitle?: {
      puzName: string;
      title: string;
    };
    /** Trigger the server to download the diagram URL from the backend. */
    downloadDiagramUrl?: true;
    /** Submit the puzzle. */
    submit?: boolean;
    /** Admin-only backdoor to force a submit. */
    ignoreSubmitErrors?: true;
    setStatus?: Puzzle105SetStatusPayload;
  };
  stepResponse: {
    checkTitleResp?: Puzzle105CheckTitleResponse;
  };
  backendState: SockAPIScopeState<ScopeType.TEAM_PUZZLE_105>;
};

export const teamPuzzle105ScopeHooks: ScopeHooks<ScopeType.TEAM_PUZZLE_105> = {
  identityFunc: (scope) => scope,
  getIdComponents: getTeamScopeIdComponents,
  update: (scopeState, payload) => {
    const {
      diagramUrl,
      movePuz,
      setConfirmedTitles,
      setCheckTitleThrottledTime,
      formFields,
      setStatus,
    } = payload;
    const { confirmedTitles, order } = scopeState;
    if (diagramUrl !== undefined) scopeState.diagramUrl = diagramUrl;
    if (movePuz !== undefined) {
      const { puzName, diff } = movePuz;
      const oldIndex = order.indexOf(puzName);
      if (oldIndex === -1)
        throw new Error(`puzzle ${puzName} not found in order`);
      const newIndex = Math.max(0, Math.min(order.length - 1, oldIndex + diff));
      order.splice(oldIndex, 1);
      order.splice(newIndex, 0, puzName);
    }
    for (const [puzName, title] of Object.entries(setConfirmedTitles ?? {})) {
      if (title === null) delete confirmedTitles[puzName];
      else confirmedTitles[puzName] = title;
    }
    if (setCheckTitleThrottledTime !== undefined) {
      if (setCheckTitleThrottledTime === null)
        delete scopeState.checkTitleThrottledTime;
      else scopeState.checkTitleThrottledTime = setCheckTitleThrottledTime;
    }
    if (formFields !== undefined)
      Object.assign(scopeState.formFields, formFields);
    if (setStatus !== undefined) scopeState.status = setStatus;
  },
};
