import { FC, SVGProps } from "react";
import Twemoji from "react-twemoji";

import { GachaPull, GachaPrizeType, Rarity } from "sockapi/gacha/pulls";
import { PuzzleMetadata } from "sockapi/puzzle-data";
import { ScopeType } from "sockapi/scope-types";
import {
  useScopeStateResolvable,
  useTeamScopeState,
} from "stores/ScopeStateStore";
import { Spinner } from "components/Loading";

/* eslint-disable unused-imports/no-unused-imports */
import OpenCannedHint from "images/icons/can-open.svg?react";
import CannedHint from "images/icons/can-closed.svg?react";
import Star3 from "images/icons/star-3.svg?react";
import Star4 from "images/icons/star-4.svg?react";
import Star5 from "images/icons/star-5.svg?react";
import Star6 from "images/icons/star-6.svg?react";
import Star7 from "images/icons/star-7.svg?react";
import Star8 from "images/icons/star-8.svg?react";
/* eslint-enable unused-imports/no-unused-imports */

export enum IconType {
  CANNED_HINT = "canned_hint",
  OPEN_CANNED_HINT = "open_canned_hint",
  STAR_3 = "star_3",
  STAR_4 = "star_4",
  STAR_5 = "star_5",
  STAR_6 = "star_6",
  STAR_7 = "star_7",
  STAR_8 = "star_8",
}

// TS believes it's a string, but it's actually a react component
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const iconToSvg: { [key in IconType]: any } = {
  [IconType.CANNED_HINT]: CannedHint,
  [IconType.OPEN_CANNED_HINT]: OpenCannedHint,
  [IconType.STAR_3]: Star3,
  [IconType.STAR_4]: Star4,
  [IconType.STAR_5]: Star5,
  [IconType.STAR_6]: Star6,
  [IconType.STAR_7]: Star7,
  [IconType.STAR_8]: Star8,
} as const;

export function Icon(props: { icon: IconType } & SVGProps<SVGSVGElement>) {
  const { icon, className = "", ...otherProps } = props;
  const svg = iconToSvg[icon] as FC<SVGProps<SVGSVGElement>>;
  return svg({ className: `emoji ${className}`, ...otherProps });
}

export const starHue = {
  [Rarity.COMMON]: 0,
  [Rarity.UNCOMMON]: 20,
  [Rarity.RARE]: 120,
  [Rarity.EPIC]: 200,
  [Rarity.LEGENDARY]: -40,
};

const starIcon = {
  [Rarity.COMMON]: IconType.STAR_4,
  [Rarity.UNCOMMON]: IconType.STAR_5,
  [Rarity.RARE]: IconType.STAR_6,
  [Rarity.EPIC]: IconType.STAR_7,
  [Rarity.LEGENDARY]: IconType.STAR_8,
};

function StarIcon(props: { rarity: Rarity } & SVGProps<SVGSVGElement>) {
  const { rarity, ...otherProps } = props;
  return (
    <Icon
      icon={starIcon[rarity]}
      style={{ filter: `hue-rotate(${starHue[rarity]}deg)` }}
      {...otherProps}
    />
  );
}

export function CompletionIcon(props: {
  numSolves: number;
  numSolveCountPuzzles?: number;
}) {
  const { numSolves, numSolveCountPuzzles } = props;
  const fraction =
    numSolveCountPuzzles === undefined ? 0 : numSolves / numSolveCountPuzzles;
  if (numSolves >= 412)
    return (
      <Twemoji tag="span" options={{ folder: "svg", ext: ".svg" }}>
        💫
      </Twemoji>
    );
  const rarity: Rarity = (() => {
    switch (true) {
      case fraction >= 1.0:
        return Rarity.LEGENDARY;
      case fraction >= 0.95:
        return Rarity.EPIC;
      case fraction >= 0.9:
        return Rarity.RARE;
      case fraction >= 0.8:
        return Rarity.UNCOMMON;
      default:
        return Rarity.COMMON;
    }
  })();
  return <StarIcon rarity={rarity} />;
}

export function PuzzleIcon(props: {
  puzData: PuzzleMetadata & { solveTime?: number };
  resolveFindCelestial?: boolean;
}) {
  const { puzData, resolveFindCelestial = false } = props;
  const {
    isStory = false,
    isCelestial = false,
    isFindCelestial = false,
    isBigMeta = false,
    rarity = Rarity.COMMON,
    solveTime,
    customPuzzleData,
  } = puzData;
  const isCustomPuzzle = customPuzzleData !== undefined;
  return isBigMeta ? (
    <Twemoji tag="span" options={{ folder: "svg", ext: ".svg" }}>
      🌌
    </Twemoji>
  ) : isCelestial || (resolveFindCelestial && isFindCelestial) ? (
    <Twemoji tag="span" options={{ folder: "svg", ext: ".svg" }}>
      💫
    </Twemoji>
  ) : isStory ? (
    <Twemoji tag="span" options={{ folder: "svg", ext: ".svg" }}>
      🎬
    </Twemoji>
  ) : isCustomPuzzle ? (
    <Twemoji tag="span" options={{ folder: "svg", ext: ".svg" }}>
      🌠
    </Twemoji>
  ) : (
    <span>
      <StarIcon
        rarity={rarity}
        className={solveTime === undefined ? "unsolved" : undefined}
      />
    </span>
  );
}

export function PrizeIcon(props: { teamId: string; prize: GachaPull }) {
  const { teamId, prize } = props;
  const teamScopeState = useTeamScopeState(teamId);
  const teamCollectiblesScopeState = useScopeStateResolvable(
    ScopeType.TEAM_COLLECTIBLES,
    teamId === null ? null : { teamId }
  );

  if (teamScopeState === null || teamCollectiblesScopeState === null)
    return <Spinner inline={true} />;
  const { puzzles } = teamScopeState;

  if (prize.type === GachaPrizeType.UNLOCK) {
    const puzData = puzzles[prize.puzName];
    // Handle puzzles getting admin-locked.
    if (puzData === undefined) return null;
    return <PuzzleIcon puzData={puzData} resolveFindCelestial />;
  }
  const icon = ((): string | null => {
    switch (prize.type) {
      case GachaPrizeType.CANNED_HINT:
        return "🥫";
      case GachaPrizeType.HINT_TOKEN:
        return "💡";
      case GachaPrizeType.JACKPOT:
        return "✨";
      case GachaPrizeType.COLLECTIBLE:
        return (
          teamCollectiblesScopeState.collectibles[prize.collectibleId]?.icon ??
          null
        );
      case GachaPrizeType.OLIVE:
        return "🔦";
      case GachaPrizeType.BLOCKS:
        return "🧊";
    }
  })();
  if (icon === null) return <Spinner inline />;
  return (
    <Twemoji tag="span" options={{ folder: "svg", ext: ".svg" }}>
      {icon}
    </Twemoji>
  );
}
