import { PropsWithChildren, HTMLProps } from "react";
import { ScopeType } from "sockapi/scope-types";
import { useNavStore } from "stores/NavStore";
import { useGlobalScopeState, useTeamScopeState } from "stores/ScopeStateStore";
import { useShouldDisplayAdmin } from "stores/ServerInteractionStore";
import { Spinner } from "components/Loading";
import settings from "settings";

interface LinkProps {
  disabled?: boolean;
  button?: true;
}

function Link({
  children,
  disabled,
  button,
  ...otherProps
}: PropsWithChildren<LinkProps> & HTMLProps<HTMLAnchorElement>) {
  if (!button) return <a {...otherProps}>{children}</a>;
  return (
    <a {...otherProps}>
      <button disabled={disabled} style={{ cursor: "unset" }} tabIndex={-1}>
        {children}
      </button>
    </a>
  );
}

export function DjangoLink({
  children,
  href,
  disabled,
  ...otherProps
}: PropsWithChildren<LinkProps> & HTMLProps<HTMLAnchorElement>) {
  if (disabled)
    return (
      <Link disabled {...otherProps}>
        {children}
      </Link>
    );
  return (
    <Link href={settings.djangoBaseUrl + href} {...otherProps}>
      {children}
    </Link>
  );
}

// A RoutedLink is a link that works both for in-app navigation with a router,
// and for browser navigation on Django pages. This is specifically true for
// navbar links. Note: `path` must be valid in both cases; if not, set `href`.
type RoutedLinkProps = {
  path: string;
} & LinkProps;

export function RoutedLink({
  children,
  path,
  href,
  disabled,
  ...otherProps
}: PropsWithChildren<RoutedLinkProps> & HTMLProps<HTMLAnchorElement>) {
  const hasRouter = useNavStore((state) => state.hasRouter);
  const setRedirectCommand = useNavStore((state) => state.setRedirectCommand);
  if (disabled)
    return (
      <Link disabled {...otherProps}>
        {children}
      </Link>
    );
  return (
    <Link
      href={href ?? path}
      onClick={(e) => {
        // do not intercept if someone is opening new tabs
        if (e.ctrlKey || e.shiftKey || !hasRouter) return;
        setRedirectCommand({ path, isManual: true });
        e.preventDefault();
      }}
      {...otherProps}
    >
      {children}
    </Link>
  );
}

// A PuzzleLink is a link to a puzzle.
type PuzzleLinkProps = {
  puzName: string;
  subpage?: "puzzle" | "solution" | "hints";
} & Omit<RoutedLinkProps, "path">;

export function PuzzleLink({
  children,
  puzName,
  subpage = "puzzle",
  ...otherProps
}: PropsWithChildren<PuzzleLinkProps> & HTMLProps<HTMLAnchorElement>) {
  // We inline this logic here so that we can treat missing scope states
  // separately from missing puzzle data.
  const shouldDisplayAdmin = useShouldDisplayAdmin();
  const huntAdminScopeState = useGlobalScopeState(
    ScopeType.HUNT_ADMIN,
    shouldDisplayAdmin
  );
  const teamScopeState = useTeamScopeState();
  if (teamScopeState === null) return <Spinner inline />;
  const puzData =
    teamScopeState.puzzles[puzName] ??
    huntAdminScopeState?.puzzles[puzName] ??
    null;
  if (puzData === null)
    return (
      <Link disabled {...otherProps}>
        {children ?? "???"}
      </Link>
    );
  const { slug } = puzData;

  const puz330 = puzName === "puz330" && subpage === "puzzle";
  if (puz330) {
    otherProps.disabled = true;
    otherProps.style ??= {};
    otherProps.style.cursor = "not-allowed";
  }
  return (
    <RoutedLink path={`/${subpage}/${slug}`} {...otherProps}>
      {children}
    </RoutedLink>
  );
}
