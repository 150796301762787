import { ScopeHooks } from "sockapi/scope-specs";
import { ScopeType } from "sockapi/scope-types";
import { ErratumSummary } from "sockapi/scope-specs/erratum-content-spec";
import { PuzzleData, PuzzlesDBData } from "sockapi/puzzle-data";
import { TeamProgressEventReason } from "sockapi/team-progress";
import { SockServerConfig } from "sockapi/server-config";

export type SockAPITeamAdminData = {
  userId: string;
  displayName: string;
  isHidden: boolean;
  isInactive: boolean;
  isTempBlocked: boolean;
};

export type AdminModifyTeamParams = {
  isInactive?: boolean;
  isHidden?: boolean;
  isTempBlocked?: boolean;
  displayName?: string;
  overrideSolved?: { [puzName: string]: boolean };
  overrideUnlocked?: { [puzName: string]: boolean };
  overridePuzzleNumExtraGuesses?: { [puzName: string]: number };
  overridePuzzleNumTotalCannedHints?: { [puzName: string]: number };
  overrideNumTotalHintTokens?: number;
  overrideHope?: number;
  overrideNumOliveWishes?: number;
  overrideNumBlocksWishes?: number;
};

export type HuntAdminScopeSpec = {
  state: {
    teamAdminData: { [teamId: string]: SockAPITeamAdminData };
    puzzles: { [puzName: string]: PuzzleData };
    numCannedHints: { [puzName: string]: number };
    errata: {
      [erratumId: string]: { puzName: string | null; timestamp: number };
    };
  };
  update: {
    addTeams?: {
      [teamId: string]: SockAPITeamAdminData;
    };
    updTeams?: {
      [teamId: string]: {
        isHidden?: boolean;
        isInactive?: boolean;
        displayName?: string;
      };
    };
    addPuzzles?: {
      [puzName: string]: PuzzleData;
    };
    numCannedHints?: { [puzName: string]: number };
    setErrata?: {
      [erratumId: string]: ErratumSummary | null;
    };
  };
  step: {
    config?: Partial<SockServerConfig>;
    teams?: {
      [teamId: string]: AdminModifyTeamParams;
    };
    reloadPuzzles?: true;
    setPuzzlesDB?: PuzzlesDBData;
    /**
     * If provided, will be set as the reason for all events
     * tagged with reasons. Otherwise, MISC without a reason text
     * would be used by default.
     */
    reason?: TeamProgressEventReason;
    /**
     * Force all timestamps generated to start at an offset from hunt
     * start. Only used for testing.
     */
    setForcedTimeOffset?: number;
  };
  stepResponse: "";
};

export const huntAdminScopeHooks: ScopeHooks<ScopeType.HUNT_ADMIN> = {
  identityFunc: (scope) => scope,
  update: (scopeState, payload) => {
    const { addTeams, updTeams, addPuzzles, numCannedHints, setErrata } =
      payload;
    Object.assign(scopeState.teamAdminData, addTeams ?? {});
    for (const [teamId, teamUpd] of Object.entries(updTeams ?? {})) {
      const teamData = scopeState.teamAdminData[teamId];
      Object.assign(teamData, teamUpd);
    }
    Object.assign(scopeState.puzzles, addPuzzles ?? {});
    if (numCannedHints !== undefined)
      Object.assign(scopeState.numCannedHints, numCannedHints);
    for (const [erratumId, erratum] of Object.entries(setErrata ?? {})) {
      if (erratum === null) delete scopeState.errata[erratumId];
      else scopeState.errata[erratumId] = erratum;
    }
  },
};
